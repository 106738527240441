import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);

  &:hover {
    color: #1e6380;
    transition: color 220ms cubic-bezier(0.38, 0.61, 0.73, 0.44);
    & h4 {
      text-decoration: underline;
    }
  }
`;

export default StyledLink;
